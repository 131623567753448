import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ComponentsModule } from '@app/shared/components/components.module';
import { StateService } from '@app/core/services/state.service';
import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-sso-test',
  templateUrl: './sso-test.component.html',
  styleUrls: ['./sso-test.component.scss'],
  standalone: true,
  imports: [CommonModule, ComponentsModule],
})
export class SsoTestComponent implements OnInit {
  saml: any;
  provider: string;

  constructor(
    public stateService: StateService,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  async ngOnInit() {
    this.stateService.testingSSO = true;

    const { provId, signIn = false } = await firstValueFrom(
      this.route.queryParams.pipe(take(1))
    );

    this.stateService.signingInWithRedirect = signIn;

    this.provider = provId;
    this.afAuth.onAuthStateChanged(async (user) => {
      console.log(user);
      if (!user) return;

      // Remove signing in param
      const urlSegments = location.href.split('/');
      this.location.replaceState(
        urlSegments[urlSegments.length - 1].replace('&signIn=true', '')
      );

      // Sign out to prevent auth issus with other SSO pages
      await this.afAuth.signOut();
    });
  }

  async signInWithRedirect() {
    // Add signing in to query params
    const urlSegments = location.href.split('/');
    this.location.replaceState(
      `${urlSegments[urlSegments.length - 1]}&signIn=true`
    );

    this.stateService.signingInWithRedirect = true;

    this.saml = await this.samlProvider(`saml.${this.provider}`);
    await this.afAuth.signInWithRedirect(this.saml);
  }

  async samlProvider(provider: string) {
    return new firebase.auth.SAMLAuthProvider(provider);
  }

  get hasUser() {
    return this.stateService.testConnectionResult;
  }

  get user() {
    return this.stateService.testConnectionResult;
  }

  get success() {
    return this.user?.email && this.user?.fName && this.user?.lName;
  }

  get signingIn() {
    return this.stateService.signingInWithRedirect;
  }
}
