import { Feature, FeatureFlagConfig } from '@vygoapp/vygo-types';

import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

type FeatureFlagMap = { [key in Feature]?: FeatureFlagConfig };

@Injectable({
  providedIn: 'root',
})
export class AppTextService {
  eventTerm = 'group session';
  eventTermCapitalised = 'Group Session';
  eventTermHyphenated = 'group-session';

  constructor(private readonly localStorageService: LocalStorageService) {
    this.getStoredFlags();
  }

  async getStoredFlags() {
    const featureFlags: FeatureFlagMap | null =
      await this.localStorageService.getObject('featureFlags');

    if (featureFlags && featureFlags[Feature.EventTerm]?.status === 'on') {
      this.eventTerm = 'event';
      this.eventTermCapitalised = 'Event';
      this.eventTermHyphenated = 'event';
    }
  }

  // PROGRAM TEXT

  get internalProgramCreationDoneTitle() {
    return 'Congratulations! The core details of your program are now configured. Your program will remain unlisted until you publish it.';
  }

  busyStatusText() {
    return `You are only available to users you have previously had interaction with. If you would like to be available to all users, please change your status back to 'Available'.`;
  }

  awayStatusText() {
    return `You are currently unavailable to all users. You cannot be contacted or booked for sessions.`;
  }

  externalLinkText(link: string) {
    return `You are about to open a link that is outside of the Vygo platform.<br><br>${link}`;
  }

  cannotRestoreMessage() {
    const message = `Your account could not be restored. Please close the app and reopen.<br><br>
    If the problem occurs again, contact us via our website.`;
    return message;
  }

  suspendedMessage() {
    const message =
      'Your account has been suspended. We have sent you an email with further details.';
    return message;
  }

  // COURSE AND SUBJECT ADD/EDIT TEXT
  pricingMessage() {
    const pricingText = `
      <h6><b>$0/hr</b> - Beginner</h6> Build your experience while supporting others.<br><br>
      <h6><b>$20/hr</b> - Intermediate</h6> This isn't your first rodeo helping others in this subject. <br><br>
      <h6><b>$30/hr</b> - Advanced</h6> You’re extremely confident in your subject knowledge and ability to help others.<br><br>
      <i>The suggested pricing above is only a guideline. Feel free to choose your hourly rate.</i>`;
    return pricingText;
  }

  free30Message() {
    const freeText = `Offering a free 30 minute session to new students removes the initial barrier for some
    students who feel like they need help, but aren't sure if it is worth it.`;
    return freeText;
  }

  freeMessage() {
    const freeText = `<b>Wow - You’re a superstar!</b><br><br>This is an excellent way to build your employability skills.<br><br><i>By clicking continue you’ve agreed to offer your support services for free.</i>`;
    return freeText;
  }

  pageTitleDefault() {
    return 'Vygo | Student Support';
  }

  groupSessions_troubleshootingLinks() {
    return [
      {
        title: 'My camera is not working',
        url: 'https://help.vygoapp.com/en/articles/4129087-my-camera-is-not-working-on-the-vygo-web-app',
      },
      {
        title: 'My audio is not working',
        url: 'https://help.vygoapp.com/en/articles/4125729-my-audio-is-not-working-on-the-vygo-web-app',
      },
    ];
  }

  programJoin_pendingApprovalTitle() {
    return `Your request to join this program is currently pending`;
  }

  programJoin_pendingApproval() {
    return `   
        <p>Access to this program requires approval.<p>If you think there has been a mistake, or you are interested in joining the program, please contact the program admin.</p>`;
    // <p>Once the program admin has added your details to the approved list, you will have access to this program.`;
  }

  // EVENT TEXT

  get groupSessions_notInProgram() {
    return `You need to join the program this ${this.eventTerm} is for to be able to attend. `;
  }

  get groupSessions_fullGroupSessionShort() {
    return `${this.eventTermCapitalised} Full`;
  }

  get groupSessions_fullGroupSessionLong() {
    return `Sorry, this ${this.eventTerm} is booked out.`;
  }

  get groupSessions_button_declineInvite() {
    return `RSVP No`;
  }

  get groupSessions_button_leave() {
    return `I Can No Longer Attend`;
  }

  get groupSessions_button_completeSession() {
    return `Complete ${this.eventTermCapitalised}`;
  }

  get groupSessions_button_acceptInvite() {
    return `RSVP Yes`;
  }

  get groupSessions_button_startVideoSession() {
    return `Start Video Room`;
  }

  get groupSessions_button_rejoinVideoSession() {
    return `Rejoin Video Room`;
  }

  get groupSessions_button_joinVideoSession() {
    return `Join Video Room`;
  }

  get groupSessions_button_editSession() {
    return `Update ${this.eventTermCapitalised}`;
  }

  get groupSessions_canAcceptInviteMessage() {
    return `If you'd like to attend this ${this.eventTerm}, select the <b>'${this.groupSessions_button_acceptInvite}'</b> button below.`;
  }

  get groupSessions_canJoinMessage() {
    return `When the ${this.eventTerm} begins, select the <b>'Join Video Room'</b> button below to join the session.`;
  }

  get groupSessions_pleaseRespond() {
    return `Please respond by selecting one of the options below.`;
  }

  get groupSessions_newSessionConfirmationMessage() {
    return `Your ${this.eventTerm} has been submitted and is currently processing. It will appear on your dashboard momentarily.<br><br>If you need to amend any detail or reschedule the ${this.eventTerm} you can do so by going to the ${this.eventTerm} and selecting '<b>Update ${this.eventTermCapitalised}</b>'`;
  }

  get groupSessions_canStartVideoMessage() {
    return `When you're ready to start the ${this.eventTerm}, select the <b>'Start Video Room'</b> button below. `;
  }

  get groupSessions_canFinishSessionMessage() {
    return `Don't forget to use the <b>'Complete Session'</b> button below at the end of your session.`;
  }

  get groupSessions_request_noPrograms() {
    return `You need to join a program and/or category that supports ${this.eventTerm}s.`;
  }

  get groupSessions_studentAccept() {
    return `You are about to <b>accept</b> the invitation to this ${this.eventTerm}.<br><br>Are you sure you want to attend this session?`;
  }

  get groupSessions_studentLeave() {
    return `You are about to <b>leave</b> this ${this.eventTerm}.<br><br><i class="medium-text">If you change your mind, you can always mark yourself as <b>attending</b> up until the session starts by going to the <b>${this.eventTerm}s page</b>, selecting '<b>Other</b>' from the status dropdown and finding this session.</i>`;
  }

  get groupSessions_mentorStartVideoSession() {
    return `Continuing will put the ${this.eventTerm} into <b>'In progress'</b> which will allow attendees to join the video room.<br><br>Are you ready to start?`;
  }

  get groupSessions_broadcastModes() {
    return [
      {
        value: false,
        text: 'Allow all attendees to broadcast',
        subtitle:
          'All attendees will be able to send audio, video and share their screens.',
      },
      {
        value: true,
        text: 'Only allow the host (me) to broadcast',
        subtitle:
          'Only the host will be able to send audio, video and share their screen.',
      },
    ];
  }

  get groupSessions_limitedSpaces() {
    return `Limited spaces available!`;
  }

  get groupSession_cannotCreate() {
    return `You cannot create ${this.eventTerm === 'event' ? 'an' : 'a'} ${
      this.eventTerm
    } while your status is not set to <b>Available</b>. Please update your status and try again.`;
  }
}
