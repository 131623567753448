import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import {
  GroupSessionMember,
  OrganisationProgram,
  Roles,
  User,
  UserAccount,
  UserGroupSession,
  UserProfile,
  UserProgramNew,
  UserSession,
  ProgramsAdded,
  StatusType,
  SSOUser,
  GroupSession,
  Organisation,
} from '@vygoapp/vygo-types';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AppTextService } from './app-text.service';

interface UserGroupSessionExtra extends UserGroupSession {
  durationText?: string;
  endDate?: number;
  participants?: GroupSessionMember[];
}

interface InsightBehavior {
  id: string;
  calendarView: any;
  insights: any;
}

@Injectable({
  providedIn: 'root',
})
export class StateService {
  environment = 'development';
  sentryReleasePrefix = 'dev.';
  region = 'Development';
  defaultUserImg = 'assets/images/default-user.png';
  updatingUserImg = false;
  isNotProd = false;

  signInWithPopup = new BehaviorSubject<boolean>(false);

  testingSSO = false;
  signingInWithRedirect = false;
  testConnectionResult: any;

  isLoaded = new BehaviorSubject<boolean>(false);
  isLoggedIn = new BehaviorSubject<boolean>(false);
  loadSideMenus = false;
  createAccount = false;
  loggingOut = false;
  logLoggedOut = true;

  endTokenListener = new Subject<boolean>();

  keyboardShown = new BehaviorSubject<boolean>(false);
  keyboardHidden = new BehaviorSubject<boolean>(true);

  canCreateGroupSessions = false;

  user: any;
  userImg = 'assets/images/default-user.png';
  userImgInitials = '';
  lastOnline = 0;
  userRole: Roles = 'MEMBER';
  userRoles = [];

  userAccount = new BehaviorSubject<UserAccount>(null);
  userProfile = new BehaviorSubject<UserProfile>(null);
  orgDetails = new BehaviorSubject<Organisation>(null);

  programsAdded = new BehaviorSubject<ProgramsAdded[]>([]);
  programsNotAdded = new BehaviorSubject<any[]>([]);
  programsListView = false;
  hasTutorProgram = false;
  hasStudentProgram = false;

  reviewingSession: UserSession;
  reviewingGroupSession: UserGroupSession;

  notifications = new BehaviorSubject<any>([]);
  unreadNotificationCount = 0;

  infoBars = [];

  viewingUser = new BehaviorSubject(
    <User & { isAvailable: boolean; canBook: boolean }>{}
  );

  isImpersonating = new BehaviorSubject<boolean>(false);

  status = new BehaviorSubject<StatusType>({
    showStatusBar: false,
    statusBarClosed: false,
    status: 0,
    statusPrev: null,
    statusText: 'Available',
    statusColor: 'success',
  });

  videoSession: any;
  videoToken: string;

  checkingIsLoaded = false;

  partnerId: string;
  partnerSubDomain = '';
  registering = false;
  freshLogin = false;

  ssoUser: SSOUser;

  programsPerPage = 10;
  programsPagination = [];

  windowWidth = new BehaviorSubject<number>(0);
  windowHeight = new BehaviorSubject<number>(0);
  mobileWidth = false;

  isMobile = window.innerWidth < 768;
  isNative = Capacitor.isNativePlatform();

  scrollPosition = new BehaviorSubject<number>(0);

  version = environment.version;
  appPlatform = Capacitor.isNativePlatform() ? 'mobile' : 'web';
  updateAvailable = false;
  noConnection = false;
  deviceType = Capacitor.getPlatform();

  firstLoad = true;

  // Platform general
  appControl = new BehaviorSubject<any>({});

  // User
  algoliaKeys = new BehaviorSubject<any>({});

  // Sessions
  upcomingSessions = new BehaviorSubject<UserSession[]>([]);
  sessionsHistory = new BehaviorSubject<UserSession[]>([]);
  dashboardSessions = new BehaviorSubject<UserSession[]>([]);

  // Events
  upcomingGroupSessions = new BehaviorSubject<UserGroupSessionExtra[]>([]);
  groupSessionsHistory = new BehaviorSubject<UserGroupSessionExtra[]>([]);
  dashboardUserGroupSessions = new BehaviorSubject<UserGroupSessionExtra[]>([]);
  dashboardOrgGroupSessions = new BehaviorSubject<GroupSession[]>([]);

  // Private Sessions/Events Listeners
  dashSessionListenersStarted = new BehaviorSubject<Record<string, boolean>>({
    dashSessions: false,
    dashUserGroupSessions: false,
    dashOrgGroupSessions: false,
  });

  // Inbox / Conversation
  inbox = new BehaviorSubject<any>([]);
  inboxMore = new BehaviorSubject<any>([]);
  unreadMessages = new BehaviorSubject<boolean>(false);
  unreadConversationsCount = new BehaviorSubject<number>(0);
  inboxLoaded = new BehaviorSubject<boolean>(false);
  stopInboxListener = new Subject<boolean>();
  inboxLoadingMore = false;
  noMoreConversations = false;
  messageSending = new BehaviorSubject<any>({});
  newConversation = new BehaviorSubject<any>({});

  // Programs
  programsRecommended = new BehaviorSubject<OrganisationProgram[]>([]);
  userPrograms = new BehaviorSubject<UserProgramNew[]>([]);
  programsJoined = new BehaviorSubject<OrganisationProgram[]>([]);
  programDrafts = new BehaviorSubject<OrganisationProgram[]>([]);
  joinAnswersUpdated = new BehaviorSubject<number>(Date.now());
  unsavedProgramChanges = false;
  unsavedQuestionChanges = false;

  // Web only variables
  alert = new BehaviorSubject<any>({});

  // Mobile only variables
  appOpenURL = '';
  ios = false;
  android = false;
  videoWindow: any;
  versionInfo = null;
  updateRequired = false;

  // Shelfs visibility
  recommendedProgramsShelf = true;
  showGreeting = true;

  // Insights
  insight = new BehaviorSubject<InsightBehavior>(null);

  constructor(private platform: Platform, private text: AppTextService) {
    this.platform.resize.subscribe(() => {
      this.windowWidth.next(window.innerWidth);
      this.windowHeight.next(window.innerHeight);
      this.isMobile = window.innerWidth < 768;
    });

    this.windowWidth.next(window.innerWidth);
    this.windowHeight.next(window.innerHeight);
  }

  getUserRoles(currentRole = '', type = 'org') {
    let userRoles = [];

    if (!currentRole.includes('Vygo')) {
      userRoles = [
        {
          title: 'MEMBER',
          description:
            'Standard user level permission with no ability to modify data or settings other than their own.',
        },
        {
          title: 'PROGRAM ADMIN',
          description:
            'Can modify programs they are the owner of and manager users within those programs.',
        },
        {
          title: 'OWNER',
          description:
            'Can modify users, permissions, programs, and organisation settings for the entire organisation.',
        },
      ];
    }

    if (this.userRole === 'VYGO ADMIN' && type === 'vygo') {
      userRoles = [
        // ...userRoles,
        {
          title: 'VYGO SUPPORT',
          description:
            'Can impersonate users and view profiles for the purposes of providing user support.',
        },
        {
          title: 'VYGO ADMIN',
          description:
            'Same permissions as an organisation owner for the organisations they manage.',
        },
      ];
    }

    if (this.userRole === 'VYGO SUPERUSER' && type === 'vygo') {
      userRoles = [
        // ...userRoles,
        {
          title: 'VYGO SUPPORT',
          description:
            'Can impersonate users and view profiles for the purposes of providing user support.',
        },
        {
          title: 'VYGO ADMIN',
          description:
            'Same permissions as an organisation owner for the organisations they manage.',
        },
        {
          title: 'VYGO SUPERUSER',
          description: 'Full access to the entire platform.',
        },
      ];
    }

    this.userRoles = userRoles;
  }

  programListViewToggle() {
    this.programsListView = !this.programsListView;
    localStorage.setItem(
      'programsListView',
      this.programsListView ? 'true' : 'false'
    );
  }

  scrolling(val: any) {
    this.scrollPosition.next(val.detail.scrollTop);
  }

  async wait(seconds = 0) {
    return new Promise((res: any, rej: any) => {
      setTimeout(() => {
        res();
      }, seconds * 1000);
    });
  }

  async setIsLoggedIn(user: any) {
    if (user) {
      const details = {
        uid: user.uid,
        email: user.email || null,
        isAnonymous: user.isAnonymous,
        providerData: user.providerData,
      };
      this.user = details;
      this.isLoggedIn.next(true);
    } else {
      this.isLoggedIn.next(false);
    }

    return;
  }

  async uniqueHash(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async setStatus() {
    switch (this.userAccount.value.status) {
      case 'active':
        this.status.next({
          ...this.status.value,
          status: 0,
          statusPrev: 0,
          statusText: 'Available',
          statusColor: 'success-bright',
        });
        break;
      case 'busy':
        this.status.next({
          ...this.status.value,
          status: 1,
          statusPrev: 1,
          statusText: 'Busy',
          statusColor: 'warning',
          showStatusBar: true,
        });
        break;
      case 'away':
        this.status.next({
          ...this.status.value,
          status: 2,
          statusPrev: 2,
          statusText: 'Away',
          statusColor: 'danger',
          showStatusBar: true,
        });
        break;
    }

    const i = this.infoBars.findIndex((x) => x.type === 'status');

    if (i > -1) {
      this.infoBars.splice(i, 1);
    }

    if (this.status.value.status) {
      const barInfo = {
        title: `You are ${this.userAccount.value.status}`,
        body:
          this.status.value.status === 1
            ? this.text.busyStatusText()
            : this.status.value.status === 2
            ? this.text.awayStatusText()
            : '',
        type: 'status',
        color:
          this.status.value.status === 1
            ? 'warning'
            : this.status.value.status === 2
            ? 'danger'
            : '',
        hasActionButton: true,
        actionButtonText: `Go Active`,
        actionButtonIcon: `checkmark-circle-outline`,
        canClose: true,
      };

      this.infoBars.push(barInfo);
    }

    this.status.value.statusPrev = this.status;
  }

  async resetState() {
    this.orgDetails.next(null);
    this.partnerId = null;

    this.programsAdded.next([]);
    this.notifications.next([]);
    this.unreadNotificationCount = 0;
    this.user = null;
    this.userAccount.next(null);
    this.userProfile.next(null);
    this.isLoaded.next(false);
    this.registering = false;
    this.firstLoad = true;
    this.endTokenListener.next(false);
    this.isImpersonating.next(false);

    this.reviewingSession = null;
    this.reviewingGroupSession = null;
    this.infoBars = [];
    this.ssoUser = null;
    this.canCreateGroupSessions = false;
    this.loadSideMenus = false;
    this.noMoreConversations = false;
    this.logLoggedOut = true;
    this.loggingOut = false;
    this.unsavedProgramChanges = false;
    this.unsavedQuestionChanges = false;
    this.lastOnline = 0;
  }

  // Reset all the bahavior subjects when a user logs out
  resetBehaviorSubjects() {
    // User behavior subjects
    this.algoliaKeys.next({});

    // Session behavior subjects
    this.upcomingSessions.next([]);
    this.sessionsHistory.next([]);
    this.dashboardSessions.next([]);

    // Events behavior subjects
    this.upcomingGroupSessions.next([]);
    this.groupSessionsHistory.next([]);
    this.dashboardUserGroupSessions.next([]);
    this.dashboardOrgGroupSessions.next([]);

    // Private Sessions/Events listeners behavior subject
    this.dashSessionListenersStarted.next({
      dashSessions: false,
      dashUserGroupSessions: false,
      dashOrgGroupSessions: false,
    });

    // Inbox behavior subjects
    this.inbox.next([]);
    this.inboxMore.next([]);
    this.unreadMessages.next(false);
    this.unreadConversationsCount.next(0);
    this.inboxLoaded.next(false);
    this.stopInboxListener = new Subject<boolean>();

    // Program behavior subjects
    this.programsRecommended.next([]);
    this.programsJoined.next([]);
    this.programDrafts.next([]);
    this.userPrograms.next([]);

    this.insight = new BehaviorSubject<InsightBehavior>(null);
  }

  storeIfExists(param: string, storeAs: string) {
    const url = window.location.href;
    param = param.replace(/[[]]/g, '$&');
    const regex = new RegExp(`[?&]${param}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return null;

    const value = decodeURIComponent(results[2].replace('/+/g', ' '));

    if (value && value.length >= 2) {
      localStorage.setItem(storeAs, value);
    }
  }

  removeIfExists(param: string) {
    localStorage.removeItem(param);
  }
}
