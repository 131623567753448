import {
  Component,
  EnvironmentInjector,
  NgZone,
  isDevMode,
} from '@angular/core';
import { init as sentryInit, setUser as sentrySetUser } from '@sentry/angular';

import { AlertService } from './core/services/alert.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AppService } from './core/services/app.service';
import { AuthService } from './core/services/auth.service';
import { BrowserCheckService } from './core/services/browser-check.service';
import { CapacitorService } from './core/services/capacitor.service';
import { Integrations } from '@sentry/tracing';
import { LocalStorageService } from './core/services/local-storage.service';
import { Location } from '@angular/common';
import { LoggedInUserService } from './core/services/logged-in-user.service';
import { NativeService } from './core/services/native.service';
import { NavigationService } from './core/services/navigation.service';
import { PipeService } from './core/services/pipe.service';
import { Platform } from '@ionic/angular';
import { StateService } from './core/services/state.service';
import { UrlService } from './core/services/url.service';
import { VersionManagementService } from './core/services/version-management.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  alertModal: any;

  loggedIn = false;
  regions = environment.firebase;

  counter = 0;
  percentDone = 0;

  constructor(
    private platform: Platform,
    // private location: Location,
    private afAuth: AngularFireAuth,
    // private stateService: StateService,
    // private browserCheck: BrowserCheckService,
    // private urlService: UrlService,
    // private versionService: VersionManagementService,
    // private pipeService: PipeService,
    // private appService: AppService,
    // private native: NativeService,
    // private capacitor: CapacitorService,
    // private ngZone: NgZone,
    // private localStorageService: LocalStorageService,
    // private alertService: AlertService,
    // private navigationService: NavigationService,
    // private authService: AuthService,
    public readonly environmentInjector: EnvironmentInjector
  ) {
    // this.localStorageService.removeExpired();
    this.platform.ready().then(async () => {
      // console.log(this.stateService.testingSSO);

      this.authRedirectResult();

      // if (!this.stateService.isNative) {
      //   // Check browser compatibility
      //   const isCompatible = await this.browserCheck.check();

      //   if (!isCompatible) {
      //     return;
      //   }
      // }

      // this.linkClickListener(); // Listen for links being clicked

      // // Begin native only stuff and set ios or android boolean
      // if (this.stateService.isNative) {
      //   this.capacitor.hideSplashScreen();
      //   this.native.requestPushPermissions();
      //   this.stateService.ios = this.platform.is('ios');
      //   this.stateService.android = this.platform.is('android');
      //   this.native.appLinksListener();
      //   this.native.platformResumePauseListener();

      //   if (this.stateService.android) {
      //     this.native.backButtonListener();
      //   }
      // }

      // // Initialize sentry - Must happen after ios or android is set if native
      // if (!isDevMode()) {
      //   this.initSentry();
      // }

      // this.capacitor.networkStatus();

      // this.authStatus();
    });
  }

  // async authStatus() {
  //   if (localStorage.getItem('region')) {
  //     // Version listener
  //     if (!isDevMode()) {
  //       this.versionService.versionListener();
  //     }

  //     // Alerts listener
  //     this.alertsListener();

  //     // App control listener
  //     this.appControlListener();

  //     this.afAuth
  //       .onAuthStateChanged(async (user: any) =>
  //         this.ngZone.run(async () => {
  //           if (this.stateService.testingSSO) {
  //             console.log('testing sso');
  //             return;
  //           }
  //           if (!user) {
  //             this.alertService.stopAlertsInterval();
  //             this.notLoggedIn();
  //             await this.localStorageService.clearAllStorage();
  //           } else if (user && !this.loggedIn && !user.isAnonymous) {
  //             this.isLoggedIn(user);
  //           } else if (user?.isAnonymous && this.stateService.isNative) {
  //             // Listen for token while user signs in with SSO in another window
  //             return this.native.tokenListener(user);
  //           } else {
  //             await this.localStorageService.clearAllStorage();
  //             this.auth.signOut();
  //           }
  //         })
  //       )
  //       .catch((e) => {
  //         console.error('Auth state change error: ', e);
  //       });
  //   } else if (!this.stateService.noConnection) {
  //     // User needs to select what region their institution is in to connect to the correct firebase
  //     this.navigationService.navRoot('region');
  //   }
  // }

  async authRedirectResult() {
    const result = await this.afAuth.getRedirectResult();
    console.log(result);
  }

  // async notLoggedIn() {
  //   if (this.loggedIn) this.stateService.appOpenURL = '';

  //   const register = this.stateService.appOpenURL.includes('/register')
  //     ? this.stateService.appOpenURL.indexOf('/register')
  //     : this.location.path().indexOf('/register');

  //   const region = this.stateService.appOpenURL.includes('/region')
  //     ? this.stateService.appOpenURL.indexOf('/region')
  //     : this.location.path().indexOf('/region');

  //   const sso = this.stateService.appOpenURL.includes('/sso')
  //     ? this.stateService.appOpenURL.indexOf('/sso')
  //     : this.location.path().indexOf('/sso');

  //   const login = this.stateService.appOpenURL.includes('/login')
  //     ? this.stateService.appOpenURL.indexOf('/login')
  //     : this.location.path().indexOf('/login');

  //   const invite = this.stateService.appOpenURL.includes('/invite')
  //     ? this.stateService.appOpenURL.indexOf('/invite')
  //     : this.location.path().indexOf('/invite');

  //   const verify = this.stateService.appOpenURL.includes('/verify')
  //     ? this.stateService.appOpenURL.indexOf('/verify')
  //     : this.location.path().indexOf('/verify');

  //   if (
  //     register < 0 &&
  //     sso < 0 &&
  //     region < 0 &&
  //     login < 0 &&
  //     invite < 0 &&
  //     verify < 0 &&
  //     !this.stateService.noConnection
  //   ) {
  //     this.navigationService.navRoot('login');
  //   }

  //   await this.stateService.setIsLoggedIn(null);
  //   this.isNotAuthenticated();
  //   await this.native.removePushListeners();
  //   this.loggedIn = false;
  // }

  // async isLoggedIn(user: any) {
  //   await this.stateService.setIsLoggedIn(user);

  //   this.isAuthenticated();

  //   // remove any invite token
  //   localStorage.removeItem('inviteToken');

  //   // Set users id against their sentry logs
  //   sentrySetUser({
  //     id: user.uid,
  //   });

  //   if (this.stateService.isNative) {
  //     this.native.startPushListeners();
  //   }

  //   this.loggedIn = true;
  // }

  // initSentry() {
  //   // Set release prefix relevant to users platform
  //   const sentryReleasePrefix = this.stateService.ios
  //     ? `${this.stateService.sentryReleasePrefix}.ios.`
  //     : this.stateService.android
  //     ? `${this.stateService.sentryReleasePrefix}.android.`
  //     : `${this.stateService.sentryReleasePrefix}.`;

  //   // Set dsn relevant to users platform
  //   const dsn = this.stateService.isNative
  //     ? 'https://3149bdae54e64ed4864b4f3b5301504f@o753509.ingest.sentry.io/5816773'
  //     : 'https://fd9e5857dece4a65abf2824187bae484@o753509.ingest.sentry.io/5791793';

  //   sentryInit({
  //     dsn,
  //     release: sentryReleasePrefix + environment.version,
  //     environment: this.stateService.environment,
  //     integrations: [new Integrations.BrowserTracing()],

  //     // // Set tracesSampleRate to 1.0 to capture 100%
  //     // // of transactions for performance monitoring.
  //     // // We recommend adjusting this value in production
  //     tracesSampleRate: 0.25,
  //   });
  // }

  // // Listen for alerts relevant to the users platform
  // async alertsListener() {
  //   this.appService
  //     .getAppAlert()
  //     .pipe(this.pipeService.docDistinctPipe())
  //     .subscribe(async (alert: any = {}) => {
  //       this.stateService.alert.next(alert);

  //       if (alert?.active) {
  //         this.navigationService.navForward('alert', {
  //           animated: false,
  //         });
  //       }
  //     });
  // }

  // // List to the app-control doc
  // async appControlListener() {
  //   this.appService
  //     .getAppControl()
  //     .pipe(this.pipeService.docDistinctPipe())
  //     .subscribe(async (appControl: any = {}) => {
  //       this.stateService.appControl.next(appControl);
  //     });
  // }

  // linkClickListener() {
  //   window.addEventListener('click', (event) =>
  //     this.urlService.checkLink(event)
  //   );
  // }

  // isAuthenticated() {
  //   this.authService.loggedOut.next(false);
  //   this.auth.getAuthProviders();
  // }

  // isNotAuthenticated() {
  //   this.authService.loggedOut.next(true);

  //   setTimeout(() => {
  //     this.stateService.resetState();
  //     this.stateService.resetBehaviorSubjects();
  //   }, 100);
  // }

  // stopImpersonating() {
  //   window.location.reload();
  // }

  // get unreadMessages() {
  //   return this.stateService.unreadMessages.value;
  // }

  // get showSideMenus() {
  //   return this.stateService.loadSideMenus;
  // }

  // get noConnection() {
  //   return this.stateService.noConnection;
  // }

  // get isImpersonating() {
  //   return this.stateService.isImpersonating.value;
  // }

  // get isMobile() {
  //   return this.stateService.isMobile;
  // }

  // get bookedSessions() {
  //   return this.stateService.dashboardSessions.value.length;
  // }

  // get loaded() {
  //   return this.stateService.isLoaded.value;
  // }

  // get authenticated() {
  //   return this.stateService.isLoggedIn.value;
  // }

  // get excludeLoaderFromPages() {
  //   return ['create-account', 'invite'].some((x) =>
  //     this.location.path().includes(x)
  //   );
  // }
}
