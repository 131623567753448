import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'vygo-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {
  /**
   * Target this instance of vygo-button
   */
  @ViewChild('vygoButton', { read: ElementRef }) vygoButton: ElementRef;

  /**
   * Allow button to be rounded or less rounded
   */
  @Input() shape: 'normal' | 'rounded' = 'rounded';

  /**
   * What does the label say
   */
  @Input() label: string;

  /**
   * Is spinner shown
   */
  @Input() spinnerShown = false;

  /**
   * What spinner should be shown
   */
  @Input() spinner: 'crescent' | 'dots' = 'crescent';

  /**
   * Icon to place at the start of the button
   */
  @Input() iconStart: string;

  /**
   * Icon to place at the end of the button
   */
  @Input() iconEnd: string;

  /**
   * Is the button an icon only without a label
   */
  @Input() iconOnly: string;

  /**
   * What style is the button
   */
  @Input() fill: 'solid' | 'tonal' | 'outline' | 'text' = 'solid';

  /**
   * What color should the button be
   */
  @Input() color:
    | 'primary'
    | 'warning'
    | 'danger'
    | 'success'
    | 'dark'
    | 'light'
    | 'medium'
    | 'white' = 'primary';

  /**
   * What width should the button be
   */
  @Input() width: 'full' | 'snug' = 'snug';

  /**
   * Show a badge if a value is present
   */
  @Input() badgeValue: number = 0;

  /**
   * Is the button disabled
   */
  @Input() disabled = false;

  /**
   * What text color should the have (This overrides the default font color)
   */
  @Input() textColor:
    | 'primary'
    | 'warning'
    | 'danger'
    | 'success'
    | 'dark'
    | 'light'
    | 'medium';

  classes: string[] = [];

  ngOnChanges(): void {
    return this.setClasses();
  }

  setClasses() {
    const extras = [];

    if (this.disabled || this.spinnerShown) {
      extras.push(
        `vygo-button-disabled${
          this.fill === 'outline'
            ? '-outline'
            : this.fill === 'text'
            ? '-text'
            : ''
        }`
      );
    }

    if (this.iconOnly) {
      extras.push('icon-only');
    }

    if (this.textColor) {
      extras.push(this.textColor);
    }

    this.classes = [
      'vygo-button',
      `vygo-button-${this.shape}`,
      `vygo-button-${this.width}`,
      `vygo-button-${this.color}`,
      `vygo-button-${this.fill}`,
      ...extras,
    ];
  }

  clicked() {
    setTimeout(() => this.vygoButton.nativeElement.blur());
  }
}
