<div
  style="
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
  "
>
  <div class="grey-box">
    <div>
      <img src="assets/images/vygo-logo.svg" class="logo" />
    </div>
    <div>
      <h3 class="medium" [class.success]="success">
        {{
          success
            ? 'SSO Connection Successfully Configured'
            : 'Test SSO Connection'
        }}
      </h3>
    </div>
    <div *ngIf="!hasUser">
      <vygo-button
        [disabled]="signingIn"
        [label]="signingIn ? 'Please wait...' : 'Connect with SSO'"
        [spinnerShown]="signingIn"
        (click)="signInWithRedirect()"
      >
      </vygo-button>
    </div>
    <div *ngIf="hasUser">
      <ul>
        <li>
          <img [src]="user?.fName ? 'assets/success.png' : 'assets/fail.png'" />
          <span [class.missing]="!user?.fName">
            {{
              user?.fName ||
                'First name not provided. Check mapping configuration.'
            }}
          </span>
        </li>
        <li>
          <img [src]="user?.lName ? 'assets/success.png' : 'assets/fail.png'" />
          <span [class.missing]="!user?.lName">
            {{
              user?.lName ||
                'Last name not provided. Check mapping configuration.'
            }}
          </span>
        </li>
        <li>
          <img [src]="user?.email ? 'assets/success.png' : 'assets/fail.png'" />
          <span [class.missing]="!user?.email">
            {{
              user?.email ||
                'Emailed not provided. Check mapping configuration.'
            }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
