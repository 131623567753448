export const environment = {
  production: true,
  version: '2023.07.01',
  versionPath: '7-0-0',
  useEmulators: false,
  loom: {
    development: 'a5f0ca26-2adb-4279-9b4c-1fd5eff96c49',
    demonstration: '2b96e190-db9e-4983-82ed-24929d32652d',
    production: '5e6ecdb9-605f-49e7-ba54-109c92551e25',
  },
  cronofy: {
    development: {
      clientId: 'nPAqGVQUu0JE6iyMD4ygvFQaQJFzKItQ',
      dataCenter: 'us',
    },
    demonstration: {
      clientId: 'RAjvcbvoLhMamBs7nApiFxwPg3L5jxmV',
      dataCenter: 'us',
    },
    production: {
      clientId: 'EcTht8W21A4VNC2dWQZzb7tPzZ0sefha',
      dataCenter: 'us',
    },
  },
  firebase: {
    Development: {
      apiKey: 'AIzaSyBZH-i-Rx24b5djP70MbKKs0pzO6dLqWtI',
      authDomain: 'next.vygo.app',
      databaseURL: 'https://vygo-dev.firebaseio.com',
      projectId: 'vygo-dev',
      storageBucket: 'vygo-dev.appspot.com',
      messagingSenderId: '969323250880',
      appId: '1:969323250880:web:2779ad5fa744d86c461925',
      measurementId: 'G-94GT3G7V3S',
    },
    UAT: {
      apiKey: 'AIzaSyBOq4zXrBJNN2UDCEaTfVtFPt6ZCoFZEOk',
      authDomain: 'auth.vygoapp.com',
      databaseURL: 'https://vygo-uat.firebaseio.com',
      projectId: 'vygo-uat',
      storageBucket: 'vygo-uat.appspot.com',
      messagingSenderId: '766625949512',
      appId: '1:766625949512:web:c03ee17688137e5341f57d',
      measurementId: 'G-SS76ZEWGEV',
    },
    Demo: {
      apiKey: 'AIzaSyCjSfpTKrQBxzBLEduk1l5X3BMTCgzoOvc',
      authDomain: 'auth.vygoapp.com',
      projectId: 'vygo-demo',
      storageBucket: 'vygo-demo.appspot.com',
      messagingSenderId: '384764076066',
      appId: '1:384764076066:web:85f5c43d43594c322c2a7b',
      measurementId: 'G-LS1PC2TKV0',
    },
    Australia: {
      apiKey: 'AIzaSyCnpdQ9Iy6ySckuzj1H1XRWurGAhf8drwg',
      authDomain: 'auth.vygoapp.com',
      databaseURL: 'https://vygo-au.firebaseio.com',
      projectId: 'vygo-au',
      storageBucket: 'vygo-au.appspot.com',
      messagingSenderId: '349524638775',
      appId: '1:349524638775:web:6827e77c5f88bbd01113b2',
      measurementId: 'G-C2MT8GHYG8',
    },
    'UK/Europe': {
      apiKey: 'AIzaSyApns-qZTmvkT8Ib5IPNibUIffETvd6pYM',
      authDomain: 'auth.vygoapp.com',
      databaseURL: 'https://vygo-eu.firebaseio.com',
      projectId: 'vygo-eu',
      storageBucket: 'vygo-eu.appspot.com',
      messagingSenderId: '519950006491',
      appId: '1:519950006491:web:f125c7a7df779db9eff4fe',
      measurementId: 'G-YY3899DR2R',
    },
    US: {
      apiKey: 'AIzaSyBOY6sWdW5vQv9-zG2iqfDnSaB6jD49LlU',
      authDomain: 'auth.vygoapp.com',
      databaseURL: 'https://vygo-us.firebaseio.com',
      projectId: 'vygo-us',
      storageBucket: 'vygo-us.appspot.com',
      messagingSenderId: '554684763991',
      appId: '1:554684763991:web:f76b8c8714070ec9f1f812',
      measurementId: 'G-M11BJCVSCZ',
    },
  },
};
