<ion-app>
  <!-- NO INTERNET CONNECTION POPOVER -->
  <!-- <app-no-internet-connection-modal
    [hidden]="!noConnection"
    style="z-index: 10"
  >
  </app-no-internet-connection-modal> -->

  <!-- APP LOADING -->
  <!-- <app-loading-modal
    [hidden]="loaded || !authenticated || excludeLoaderFromPages"
    style="z-index: 10"
  ></app-loading-modal> -->

  <!-- IMPERSONATING USER BANNER -->
  <!-- <ion-item
    class="impersonating-item"
    color="warning"
    lines="none"
    [hidden]="!isImpersonating"
  >
    <ion-icon slot="start" name="finger-print-outline"></ion-icon>
    <ion-label> You're impersonating another user </ion-label>
    <vygo-button
      label="Stop"
      width="full"
      color="danger"
      (click)="stopImpersonating()"
    >
    </vygo-button>
  </ion-item> -->

  <!-- MAIN ROUTER OUTLET -->
  <ion-router-outlet
    id="content"
    [environmentInjector]="environmentInjector"
  ></ion-router-outlet>

  <!-- NOTIFICATIONS SIDE MENU -->
  <!-- <ion-menu
    class="right-menu"
    side="end"
    contentId="content"
    menuId="notificationsMenu"
    [swipeGesture]="false"
    *ngIf="showSideMenus && loaded"
  >
    <app-notifications-menu class="ion-page"></app-notifications-menu>
  </ion-menu> -->
</ion-app>
