import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BadgeComponent } from './badge/badge.component';
import { ButtonComponent } from './button/button.component';
import { CardComponent } from './card/card.component';
import { CardHeaderComponent } from './card/card-header/card-header.component';
import { CommonModule } from '@angular/common';
import { InlineSelectComponent } from './inline-select/inline-select.component';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';

const COMPONENTS = [
  ButtonComponent,
  CardComponent,
  CardHeaderComponent,
  InlineSelectComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    BadgeComponent,
  ],
  exports: [...COMPONENTS],
})
export class ComponentsModule {}
