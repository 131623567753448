import { Component, Input } from '@angular/core';

import { CommonModule } from '@angular/common';

@Component({
  selector: 'vygo-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class BadgeComponent {
  /**
   * Text to be displayed inside the badge
   */
  @Input() text = '';

  /**
   * Background color of the badge
   */
  @Input() color: 'dark' | 'light' = 'light';

  get classes(): string[] {
    return ['vygo-badge', `vygo-badge-${this.color}`];
  }
}
