import { Duration, add } from 'date-fns';

import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  updateUserLocalStorage(uid: string, name: string, value: any) {
    let userStorage: any = localStorage.getItem(uid);
    userStorage = JSON.parse(userStorage) || {};
    userStorage[name] = value;
    localStorage.setItem(uid, JSON.stringify(userStorage));
  }

  getLocalStorageValue(uid: string, key: string) {
    let userStorage: any = localStorage.getItem(uid);
    userStorage = JSON.parse(userStorage) || {};

    return userStorage[key] || null;
  }

  async getExpiring(name: string) {
    try {
      const { value } = await this.getStorage(name);
      if (!value) return null;
      const data = JSON.parse(value);
      if (data?.expires && data.expires < Date.now()) {
        await this.removeStorage(name);
        return null;
      }
      return data.value;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async setExpiring(name: string, value: any, expires: Duration) {
    try {
      await this.setStorage(
        name,
        JSON.stringify({
          value,
          expires: add(new Date(), expires).getTime(),
        })
      );
    } catch (e) {
      console.error(e);
    }
  }

  async removeExpired() {
    const { keys } = await this.getStorageKeys();
    for (const key of keys) {
      try {
        const { value } = await this.getStorage(key);
        if (!this.canParseJSON(value)) continue; // Check if can parse string or next
        const data = JSON.parse(value);
        if (data?.expires && data.expires < Date.now()) {
          await this.removeStorage(key);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  async clearAllStorage() {
    const { keys } = await this.getStorageKeys();
    for (const key of keys) {
      try {
        await this.removeStorage(key);
      } catch (e) {
        console.error(e);
      }
    }
  }

  async remove(key: string) {
    try {
      await this.removeStorage(key);
    } catch (e) {
      console.error(e);
    }
  }

  async storeString(key: string, value: string) {
    try {
      await this.setStorage(key, value);
    } catch (e) {
      console.error(e);
    }
  }

  async storeObject(key: string, value: any) {
    try {
      await this.setStorage(key, JSON.stringify(value));
    } catch (e) {
      console.error(e);
    }
  }

  async getString(key: string) {
    try {
      const { value } = await this.getStorage(key);
      return value;
    } catch (e) {
      console.error(e);
    }
  }

  async getObject(key: string): Promise<any> {
    try {
      const { value } = await this.getStorage(key);
      if (!this.canParseJSON(value)) return null; // Check if can parse string or null
      return JSON.parse(value);
    } catch (e) {
      console.error(e);
    }
  }

  setStorage(key: string, value: string) {
    return Preferences.set({ key, value });
  }

  getStorage(key: string) {
    return Preferences.get({
      key,
    });
  }

  getStorageKeys() {
    return Preferences.keys();
  }

  removeStorage(key: string) {
    return Preferences.remove({ key });
  }

  canParseJSON(str: string) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
}
