import {
  AngularFireAnalyticsModule,
  COLLECTION_ENABLED,
  DEBUG_MODE,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import {
  AngularFireAuthModule,
  USE_EMULATOR as USE_AUTH_EMULATOR,
} from '@angular/fire/compat/auth';
import {
  AngularFireFunctionsModule,
  REGION,
} from '@angular/fire/compat/functions';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import {
  AngularFireStorageModule,
  USE_EMULATOR as USE_STORAGE_EMULATOR,
} from '@angular/fire/compat/storage';
import {
  AngularFirestoreModule,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
} from '@angular/fire/compat/firestore';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import {
  IonicModule,
  IonicRouteStrategy,
  iosTransitionAnimation,
} from '@ionic/angular';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { getApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonsModule } from './commons/commons.module';
import { CoreModule } from './core/core.module';
import { FirebaseConfigService } from './core/firebase/firebase-config.service';
import { HttpClientModule } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from './shared/shared.module';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { environment } from '@environment/environment';
import { createErrorHandler as sentryCreateErrorHandler } from '@sentry/angular';

// export function initializeApp(appConfig: FirebaseConfigService) {
//   return appConfig.fireConfig('config');
// }

// export function appRegion(appConfig: FirebaseConfigService) {
//   return appConfig.fireConfig('cloud-region');
// }

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
      navAnimation: iosTransitionAnimation,
    }),
    // CoreModule,
    // CommonsModule,
    // SharedModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireAnalyticsModule,
    HammerModule,
    MatNativeDateModule,
    RouterModule,
    MatTooltipModule,
    AngularFireModule.initializeApp(environment.firebase.Development),
    // // provideFirebaseApp(() => getApp()),
    // // provideFirestore(() => getFirestore()),
    // AngularFireModule,
    AngularFireAuthModule,
    // AngularFirestoreModule,
    // AngularFireAuthGuardModule,
    // AngularFireStorageModule,
    // AngularFireFunctionsModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ScreenTrackingService,
    UserTrackingService,
    { provide: DEBUG_MODE, useValue: false },
    { provide: COLLECTION_ENABLED, useValue: !environment.useEmulators },
    {
      provide: ErrorHandler,
      useValue: sentryCreateErrorHandler({
        showDialog: false,
      }),
    },
    // {
    //   provide: REGION,
    //   deps: [FirebaseConfigService],
    //   useFactory: appRegion,
    // },
    // {
    //   provide: FIREBASE_OPTIONS,
    //   deps: [FirebaseConfigService],
    //   useFactory: initializeApp,
    // },
    // {
    //   provide: USE_AUTH_EMULATOR,
    //   useValue: environment.useEmulators
    //     ? ['http://localhost:9099']
    //     : undefined,
    // },
    // {
    //   provide: USE_FIRESTORE_EMULATOR,
    //   useValue: environment.useEmulators ? ['localhost', 8080] : undefined,
    // },
    // {
    //   provide: USE_FUNCTIONS_EMULATOR,
    //   useValue: environment.useEmulators ? ['localhost', 5001] : undefined,
    // },
    // {
    //   provide: USE_STORAGE_EMULATOR,
    //   useValue: environment.useEmulators ? ['localhost', 9199] : undefined,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
