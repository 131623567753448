<button
  #vygoButton
  [ngClass]="classes"
  [disabled]="disabled || spinnerShown"
  (click)="clicked()"
>
  <ion-icon
    class="start"
    [class.invisible]="spinnerShown"
    *ngIf="iconStart && !iconOnly"
    [name]="iconStart"
  ></ion-icon>
  <ion-spinner color="dark" [name]="spinner" *ngIf="spinnerShown"></ion-spinner>
  <div class="badge" *ngIf="badgeValue">
    {{ badgeValue }}
  </div>
  <span [class.invisible]="spinnerShown" *ngIf="label && !iconOnly">
    {{ label }}
  </span>
  <ion-icon
    class="end"
    [class.invisible]="spinnerShown"
    *ngIf="iconEnd && !iconOnly"
    [name]="iconEnd"
  ></ion-icon>
  <ion-icon *ngIf="iconOnly && !spinnerShown" [name]="iconOnly"></ion-icon>
</button>
