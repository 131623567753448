import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AccountModalComponent } from './shared/layout/menus/components/account-modal/account-modal.component';
import { AccountPage } from './pages/account/account.page';
import { AdminPage } from './pages/admin/admin.page';
import { AlertGuard } from './shared/route-guards/alert.guard';
import { AlertPage } from './pages/alert/alert.page';
import { AuthGuardService } from './shared/route-guards/auth-guard.service';
import { ConversationPage } from './pages/conversation/conversation.page';
import { CreateAccountGuard } from './shared/route-guards/create-account.guard';
import { CreateAccountPage } from './pages/create-account/create-account.page';
import { InvitePage } from './pages/invite/invite.page';
import { LoginPage } from './pages/login/login.page';
import { LogoutPage } from './pages/logout/logout.page';
import { NgModule } from '@angular/core';
import { OnboardingGuard } from './shared/route-guards/onboarding.guard';
import { OnboardingPage } from './pages/onboarding/onboarding.page';
import { ProfilePage } from './pages/profile/profile.page';
import { RegionPage } from './pages/region/region.page';
import { RegisterPage } from './pages/register/register.page';
import { SsoPage } from './pages/sso/sso.page';
import { SsoTestComponent } from './pages/sso-test/sso-test.component';
import { UnauthGuardService } from './shared/route-guards/unauth-guard.service';
import { UpdateGuard } from './shared/route-guards/update.guard';
import { UpdatePage } from './pages/update/update.page';
import { UserPage } from './pages/user/user.page';
import { VerifyPage } from './pages/verify/verify.page';

const routes: Routes = [
  // {
  //   path: 'region',
  //   component: RegionPage,
  //   canActivate: [UnauthGuardService],
  // },
  // {
  //   path: 'login',
  //   component: LoginPage,
  //   canActivate: [UnauthGuardService],
  // },
  // {
  //   path: 'register',
  //   component: RegisterPage,
  //   canActivate: [UnauthGuardService],
  // },
  // {
  //   path: 'invite/:token',
  //   component: InvitePage,
  //   canActivate: [UnauthGuardService],
  // },
  // {
  //   path: 'logout',
  //   component: LogoutPage,
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'verify',
  //   component: VerifyPage,
  // },
  // {
  //   path: 'verify/:token',
  //   component: VerifyPage,
  // },
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('./pages/organisation/organisation.module').then(
  //       (m) => m.OrganisationPageModule
  //     ),
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'onboarding',
  //   component: OnboardingPage,
  //   canActivate: [AuthGuardService, OnboardingGuard],
  //   canDeactivate: [OnboardingGuard],
  // },
  // {
  //   path: 'account',
  //   component: AccountPage,
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'account/profile',
  //   redirectTo: 'profile',
  // },
  // {
  //   path: 'account/:settings',
  //   component: AccountPage,
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'profile',
  //   component: ProfilePage,
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'session/:id',
  //   redirectTo: 'sessions/view/:id',
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'session/:id/:segment',
  //   redirectTo: 'sessions/view/:id',
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'group-session/:id',
  //   redirectTo: 'group-sessions/view/:id',
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'group-session/:id/:segment',
  //   redirectTo: 'group-sessions/view/:id',
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'conversation/:id',
  //   component: ConversationPage,
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'user/:id',
  //   component: UserPage,
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'program/create',
  //   redirectTo: 'programs/new/create',
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'program/:id',
  //   redirectTo: 'programs/view/:id',
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'program/:id/:segment',
  //   redirectTo: 'programs/view/:id/:segment',
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'account-mobile',
  //   component: AccountModalComponent,
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'create-account',
  //   component: CreateAccountPage,
  //   canActivate: [AuthGuardService, CreateAccountGuard],
  //   canDeactivate: [CreateAccountGuard],
  // },
  // {
  //   path: 'alert',
  //   component: AlertPage,
  //   canDeactivate: [AlertGuard],
  //   canActivate: [AlertGuard],
  // },
  // {
  //   path: 'update',
  //   component: UpdatePage,
  //   canDeactivate: [UpdateGuard],
  //   canActivate: [UpdateGuard],
  // },
  // {
  //   path: 'sso',
  //   component: SsoPage,
  //   canActivate: [UnauthGuardService],
  // },
  // {
  //   path: 'admin',
  //   component: AdminPage,
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'admin/:segment',
  //   component: AdminPage,
  //   canActivate: [AuthGuardService],
  // },
  {
    path: 'sso-test',
    component: SsoTestComponent,
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
